

.main-navigation {
  @include box-sizing();
  clear: both;
  display: block;
  float: left;
  width: 100%;

  @include up-to(medium) {
  }
}
  .main-navigation ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
    .main-navigation li {
      display: inline-block;
      float: left;
      margin-right: 15px;

      text-transform: uppercase;
      @include type-setting(0);

      height: 60px;
      line-height: 60px;
      
      @include up-to(large) {
        height: 30px;
        line-height: 30px;
      }

    }
      .main-navigation a {
        padding: 1px 1px 0 1px;
      }

      .item-posts {
        a {
          color: $color_secondary;

          &:hover,
          &.active {
            background-color: $color_secondary;
            color: $white;
          }
        }
      }

      .items-projets {
        a {
          color: $black;

          &:hover,
          &.active {
            background-color: $black;
            color: $white;
          }
        }
      }

      .items-identite {
        a {
          color: $color_primary;

          &:hover,
          &.active {
            background-color: $color_primary;
            color: $white;
          }
        }
      }

      %item-img {
        background-repeat: no-repeat;
        background-position: center center;
        float: right !important;
        margin-right: 0 !important;
        margin-left: 10px !important;
        a {
          display: block;
          width: 20px;
          height: 100%;
        }
      }

      li.typoqui  {
        background-image: url('../images/TYPO_picto_croix1.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 7px;
        padding-left: 10px;
      }


      .item-instagram {
        @extend %item-img;
        background-image: url('../images/TYPO_picto_instagram.png');
      }
      .item-mail {
        @extend %item-img;
        background-image: url('../images/TYPO_picto_@2.png');
      }
      .item-pintarest {
        @extend %item-img;
        background-image: url('../images/TYPO_picto_pinterest.png');

      }


.main-navigation .current-page-item a,
.main-navigation .current-menu-item a,
.main-navigation .current-page-ancestor .current-page-ancestor a {
  color: $color_link_hover;
}
.current-page-ancestor,
.current-menu-ancestor {
  .sub-menu { display: block; }
}




// MENU MOBILE
.navigation-trigger {
  @include box-sizing();
  width: 100%;
  float: left;
  padding: 0;
  border-bottom: 10px solid #aaa;
  padding: 5px 0;

  @include at-least(medium){
    display: none;
  }
}
  #trigger-out {
    display: none;
    position: absolute;
    right: 11px;
    top: 11px;

    &:hover {
      cursor: pointer;
    }
    @include up-to(medium) {
      display: block;
    }
  }


