/* 
 * Colors
 * 
 * @package sasskit
 */



// Color Scheme
// examples based on the official Sass styleguide
// =============

// Descriptive colors (example)
$green                        : #00d5a4;
$blue                         : #3030ff;


// Grey nuances
$black                        : #000 !default;
$gray_darker                  : #222 !default;
$gray_dark                    : #333 !default;
$gray                         : #555 !default;
$gray_light                   : #b2b2b2 !default;
$gray_lighter                 : #ececec !default;
$white                        : #fff !default;

// Main color palette
$color_primary                : $green;
$color_secondary              : $blue;

// Common colors
$color_background             : $white;
$color_text                   : $gray_darker;

// Links
$color_link                   : $color_primary;
$color_link_hover             : $color_secondary;
$color_link_visited           : $color_secondary;

// Code
$color_code                   : #333;
$color_code_background        : #f3f3f3;
$color_pre                    : #d4d4d4;
$color_pre_background         : #333;

// Selections
$color_selection              : #b3d4fc;

// Messages
$color_message                : #f4ecbb;
$color_success                : #2cde2c;
$color_warning                : #cf8600;
$color_important              : #d00;
$color_notice                 : #66b;




