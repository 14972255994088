/*
 * Typography
 *
 * @package sasskit
 */



// Not needed
// @import "fonts";



// Base Sizes
// ==========

$base_font_size               : 10px !default;

$base_line_half               : 10px;
$base_line_height             : 1.45 !default;


$type-scale: (
  -1: 1rem, // rubriques + copyright
  0: 1.2rem, // date + fonction + liste projets + list clients + typoqui
  1: 1.3rem, // logo typodepoivre + client
);

$line-heights: (
  -1: $base_line_height,
  0: $base_line_height, 
  1: $base_line_height, 
);


@function type-scale($level) {
  @return map-get($type-scale, $level);
}

@function line-height($level) {
  @return map-get($line-heights, $level);
}

@mixin type-setting($level: 0) {
  font-size: type-scale($level);
  line-height: line-height($level);
}

@mixin fontsize($size: $base_font_size) {
  font-size: #{$size}px;
  font-size: ( get_rem($size) )rem;
}



/*
 * TYPOGRAPHY VARIABLES SETTINGS
 */

// Google Font Families
$Neue_bold                      : 'HelveticaNeueLTStd-Bd';
$Neue_medium                    : 'HelveticaNeueLT-Medium';
$Neue_light                     : 'HelveticaNeueLT-Light';


// Default Fonts
$font_family_sans               : Helvetica, Arial, sans-serif !default;
$font_family_serif              : Georgia, Times New Roman, serif !default;
$font_family_mono               : Menlo, Consolas, monospace !default;

// Typo Attributions
$font_family_paragraph          : $Neue_medium, $font_family_sans;
$font_family_title              : $Neue_bold, $font_family_sans;
$font_family_nav                : $Neue_medium, $font_family_sans;

// Set weights
$heavy              : 800;
$bold               : 700;
$medium             : 600;
$regular            : 400;
$book               : 300;
$light              : 200;
$thin               : 100;


/*
 * Imports typo parts
 */
@import "copy";
@import "heading";
@import "fonts";


/*
 * FOR RESPONSIVE FONT SIZE. 
 * Adjust as you need.
 */

body {
  font-family: $Neue_medium;
  @include type-setting(0);
}

html {
  @include up-to(wide) {
    font-size: 62.5%;
  }
  @include at-least(wide) {
    font-size: 62.5%;
  }
}


