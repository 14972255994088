/* 
 * Header
 * 
 * @package sasskit
 */


.header {
  border-bottom: 1px solid;
  margin-bottom: 10px;
  height: 60px;
  line-height: 60px;

  @include up-to(large) {
    height: auto;
    padding-bottom: 10px;
    
    & > div {
      clear: both;
      margin-left: 0 !important;
    }
  }
}


.logo {
  float: left;
  padding-left: 30px;
  background-image: url('../images/TYPO_picto_croix1.png');
  background-repeat: no-repeat;
  background-position: top 45% left;

  h1  {
    display: inline-block;
  }
}