/* 
 * templates/home
 * 
 * @package sasskit
 */


.list-item {
  position: relative;
  display: block;
  height: auto;
  margin-bottom: 10px;



  &:hover {

    cursor: pointer;

    .item-content {
      display: block;
    }
  }

  @include up-to(medium) {

    &:nth-child(2n + 1) {
      margin-left: 0;
      clear: both;
    }
    
  }

  @include at-least(medium) {

    &:nth-child(5n + 1) {
      margin-left: 0;
      clear: both;
    }
    
  }


}


  %item-content {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-top: 10;
    padding-left: 10px;
    color: $white;
    opacity: 0.85;

    @include transition(all, 0.5s); 

    a:hover {
      color: $white;
    }

    h2 {
      font-family: $Neue_light;
      font-weight: normal;
      
    }
  }


  .item-post {
    .item-content {
      @extend %item-content;
      background-color: $color_primary;
    }

    .meta-separator {
      color: $color_secondary;
    }
  }

  .item-project {
    .item-content {
      @extend %item-content;
      background-color: $color_secondary;
    }
    
    .meta-separator {
      color: $color_primary;
    }

  }


.list-item-last {
  img {
    margin: auto;
  }
  float: left;
}


#grid[data-columns]::before {

  @include up-to(medium) {
    content: '2 .s-2col';
  }

  @include at-least(medium) {
    content: '5 .s-1col';
  }

  @include at-least(large) {
    content: '5 .s-1col';
  }

}


#index_frise {
    width: 100%;
    height: 52px;
    background: url('../images/index_frise.png') top center no-repeat;
}

#entrez {
  opacity: 0;
}

