/* 
 * Links
 * 
 * @package sasskit
 */



a {
  color: inherit;
  text-decoration: none;

  @include transition(all, 0.2s);
}
a:hover,
a:focus,
a:active {
}
