/* 
 * templates/single
 * 
 * @package sasskit
 */


.project-title {
  background-color: $gray_lighter;
  padding: 10px;
  margin-bottom: 10px;
  
  h1 {
    color: $black;
    font-family: $Neue_bold, $font_family_sans;
  }

}

.project-aside {
  background-color: $gray_lighter;
  padding: 10px;
  margin-bottom: 20px;
  font-family: $Neue_light, $font_family_sans;
}

  .project-meta {
    margin-bottom: 40px;

    h3 {
      font-family: $Neue_bold, $font_family_sans;
      text-transform: none;
      margin: 0;
      color: $color_secondary;
    }
    p {
      margin-bottom: 0;
    }
    
    time {
      font-family: $Neue_bold, $font_family_sans;
    }
  }

    .project-text-main {
      font-family: $Neue_bold, $font_family_sans;
    }
    .project-text-compl {
      font-family: $Neue_light, $font_family_sans;
    }

  .projet-nav {
    letter-spacing: 0.02em;
    a:hover {
      font-family: $Neue_medium;
    }
    a.active {
      font-family: $Neue_medium, $font_family_sans;
    }
  }

  .project-medias {
    width: 90%;
  }

  .medias-navigation {
    float: right;
    margin-right: 5%;
    margin-top: 20px;

    a {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

.separator {
  display: block;
  clear: both;

}

.copyright {
  p {
    font-family: $Neue_light, $font_family_sans;
    @include type-setting(-1);
    color: $gray_light;
  }
}




/*
 * TYPOQUI
 */

.typoqui-blocs {
  
  & > div:nth-child(4) {

    @include up-to(medium) {
      clear: both;
      margin-left: 0;
    }

  }
}

.typoqui_bloc {
  margin-bottom: 10px;
  
  h4 {
    font-family: $Neue_bold;
    margin-top: 0;
    margin-bottom: 10px;
  }

  img {
    margin-bottom: 1em;
  }
}

.typoqui_image_1 {

}
.typoqui_image_2 {
  margin-top: 193px;
}
.typoqui_image_3 {
  margin-top: 156px;
}
.typoqui_image_portrait {
  
}


.typoqui_contacts {
  @include box-sizing;
  height: 173px;

  padding: 22px 20px 20px 25px;
  background-image: url('../images/TYPO_cadre_trame.png');
  background-size:     cover;         
  background-repeat:   no-repeat;
  background-position: center center;
  @include type-setting(1);

  h4 {
    margin-bottom: 6px;
  }

  p {
    font-family: $Neue_light;
    margin-bottom: 5px;
  }
  
  .email {
    display: block;
    background-image: url('../images/TYPO_picto_@1.png');
    background-repeat: no-repeat;
    background-position: center center;
    width: 20px;
    height: 20px;
    margin-top: 7px;
  }

  @include up-to(medium) {
    width: 230px;
    height: 173px;
  }
  @include up-to(large) {
    height: auto;
  }
}

.typoqui_clients {
  font-family: $Neue_light;
  @include type-setting(1);

  p {
    margin: 0;
  }

  span {
    color: $color_primary;
  }
}

.typoqui_message {
  @include box-sizing;
  @include type-setting(1);

  height: 173px;
  background-color: $color_primary;
  padding: 39px 10px;

  p {
    color: $white;
  }

  @include up-to(large) {
    height: auto;
  }

}

.typoqui_binomes {
  @include box-sizing;
  
  height: 173px;
  background-color: $gray_lighter;
  padding: 8px 10px 8px 14px;
  height: 173px;

  h4 {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    font-family: $Neue_light;
    letter-spacing: 0.02em;

    &:before {
      content: '×';
      display: block;
      float: left;
      margin-bottom: 10px;
      margin-right: 5px;
      color: $color_primary;
    }
    
    strong {
      font-weight: 400;
      font-family: $Neue_medium;
      color: $color_secondary;
    }
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }

  @include up-to(large) {
    height: auto;
  }

}


.typoqui_talents {
 
  p {
    font-family: $Neue_light;
    color: $color_primary;
    margin-bottom: 15px;
    letter-spacing: 0.02em;
  }
  h4 {
    margin-bottom: 5px;
  }
  em {
    font-style: normal;
    color: $black;
  }
}
