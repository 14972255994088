/* 
 * Tables
 * 
 * @package sasskit
 */

$table_background:                  transparent !default;
$table_background_accent:           $gray_light !default; // for striping
$table_background_hover:            $gray_dark !default;
$table_border:                      $gray_dark !default;


table {
	margin: 0 0 1.5em;
	width: 100%;
}
th {
	font-weight: bold;
}
tr { 
  vertical-align: top;
}