/* 
 * Mixins
 * 
 * @package sasskit
 *
 * from here : http://web-design-weekly.com/2013/05/12/handy-sass-mixins/
 * from bootstrap
 * from Inuit.scss
 * forme https://github.com/ry5n/rem
 *
 */


// ADDS A BROWSER PREFIX TO THE PROPERTY
@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
   -khtml-#{$property}: #{$value};
     -moz-#{$property}: #{$value};
      -ms-#{$property}: #{$value};
       -o-#{$property}: #{$value};
          #{$property}: #{$value};
}



// Responsive Breakpoints
@mixin at-least($point) {
  @if $point == wide {
    @media (min-width: $wide_breakpoint) { @content; }
  }
  @else if $point == large {
    @media (min-width: $large_breakpoint) { @content; }
  }
  @else if $point == medium {
    @media (min-width: $medium_breakpoint) { @content; }
  }
  @else if $point == minimal {
    @media (min-width: $minimal_breakpoint) { @content; }
  }  
}

@mixin up-to($point) {
  @if $point == wide {
    @media (max-width: $wide_breakpoint) { @content; }
  }
  @else if $point == large {
    @media (max-width: $large_breakpoint) { @content; }
  }
  @else if $point == medium {
    @media (max-width: $medium_breakpoint) { @content; }
  }
}


// Box-sizing
@mixin box-sizing($type: "border") {
  /* Acceptable values are border, content, and padding - content is the default W3C model */ 
  box-sizing: #{$type}-box;
  -webkit-box-sizing: #{$type}-box;
  -moz-box-sizing: #{$type}-box;
}


// Clearfix
@mixin clearfix() {
  clear: both;
  &:before,
  &:after{
    content: " "; 
    display: table; 
  }
  &:after{
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  &{
    *zoom: 1;
  }
}


@mixin word-wrap() {
  word-break:      break-word;
  -webkit-hyphens: auto;
  -moz-hyphens:    auto;
  hyphens:         auto;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



// Absolute positioned
@mixin abs-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  position: absolute;
}


// Aboslute Centering
@mixin abs-centered () {
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

// centered in viewport
@mixin abs-centered-fixed () {
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: fixed;
  top: 0; left: 0; bottom: 0; right: 0;
  z-index: 999;
}

@mixin abs-centered-responsive () {
  width: 60%; 
  height: 60%;
  min-width: 400px;
  max-width: 500px;
  padding: 40px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}




// Opacity

@mixin opacity($opacity) {
  filter: unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})");
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
  opacity: $opacity;
}

// Drop shadows
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

// Transitions
@mixin transition($transition...) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
     -moz-transition-delay: $transition-delay;
       -o-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
     -moz-transition-duration: $transition-duration;
       -o-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}

// Transformations
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
     -moz-transform: rotate($degrees);
      -ms-transform: rotate($degrees);
       -o-transform: rotate($degrees);
          transform: rotate($degrees);
}
@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
     -moz-transform: scale($ratio);
      -ms-transform: scale($ratio);
       -o-transform: scale($ratio);
          transform: scale($ratio);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
     -moz-transform: translate($x, $y);
      -ms-transform: translate($x, $y);
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}
@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
     -moz-transform: skew($x, $y);
      -ms-transform: skewX($x) skewY($y); // See https://github.com/twitter/bootstrap/issues/4885
       -o-transform: skew($x, $y);
          transform: skew($x, $y);
  -webkit-backface-visibility: hidden; // See https://github.com/twitter/bootstrap/issues/5319
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
     -moz-transform: translate3d($x, $y, $z);
       -o-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}


// Border radius

@mixin border-radius($radius) {
  @include css3-prefix('border-radius', $radius);
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// Single side border-radius

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}


// Placeholder text

@mixin placeholder($color: $placeholderText) {
  &::-webkit-input-placeholder {
   color: $color;
  }

  &:-moz-placeholder { /* Firefox 18- */
     color: $color;  
  }

  &::-moz-placeholder {  /* Firefox 19+ */
     color: $color;  
  }

  &:-ms-input-placeholder {  
     color: $color;  
  }
}


// Shadow mixin (http://codepen.io/hugo/pen/xzjGB)

@mixin shade($type, $color: #3498db, $depth: 20, $angle: 135deg, $long: false, $fade: false) {             
  $angle: ($angle - 90);             
  $x: 1.5 * cos($angle) + 0px;  
  $y: 1.5 * sin($angle) + 0px;  
  $darken: (lightness($color)/$depth)/2;
  $opacify: 0; 
  $shadow: ();  
            
  @if $long == true{              
    $darken:0;
  }  
            
  @if $fade == true {
    $opacify: (opacity($color)/$depth) ;
    // added this for rendering in some browsers, remove if you like.
    @include translateZ(0); // requires Compass
  }
            
  @for $i from 1 through $depth {
    $shadow: $shadow, $i*$x $i*$y 0 hsla(hue($color) , saturation($color), (lightness($color) - ($i * $darken)), 1 - ($i * $opacify));     
  }       
  #{$type}-shadow: $shadow;
}


// Webkit-style focus

@mixin tab-focus() {
  // Default
  outline: thin dotted #333;
  // Webkit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}


// IE7 inline-block
@mixin ie7-inline-block() {
  *display: inline; /* IE7 inline-block hack */
  *zoom: 1;
}


// Retina Images mixin
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}


// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background: $start-color;
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Opera 12
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

 
/**
 * Courtesy of @integralist: twitter.com/integralist/status/260484115315437569
 */
@mixin keyframe ($animation-name){
    @-webkit-keyframes $animation-name{
        @content;
    }

    @-moz-keyframes $animation-name{
        @content;
    }

    @-ms-keyframes $animation-name{
        @content;
    }

    @-o-keyframes $animation-name{
        @content;
    }

    @keyframes $animation-name{
        @content;
    }
}


