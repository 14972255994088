
// Titles

h1 {
  @include type-setting(1);
  text-transform: uppercase;
  margin: 0;
  font-weight: $regular;
}

h2 {
  @include type-setting(-1);
  text-transform: uppercase;
  font-weight: $regular;
  letter-spacing: 0.1em;
}

h3 {
  @include type-setting(1);
  text-transform: uppercase;
  font-weight: $regular;
}

h4 {
  @include type-setting(1);
  font-weight: $regular;
}

h5 {
  @include type-setting(1);
  font-weight: $regular;
}

h6 {
  @include type-setting(1);
  font-weight: $regular;
}