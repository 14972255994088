/* 
 * Placeholders & utility classes
 * 
 * @package sasskit
 */

 


/* 
 * Layout
 */

.is-on-left {
  float: left !important; }

.is-on-right {
  float: right !important; }

.has-no-float {
  float: none !important;
  clear: none !important; }

.has-no-clear {
  clear: none !important; }

.is-hidden {
  display: none; }

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
 
.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.is-fixed {
  width: 100%;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 99; }

.is-bordered { 
  border: 1px solid #000;
}

.center {
    text-align: center;
    img {
      display: inline;
    }
}

.is-clearfixed,
.clearfix,
.cf {
  @include clearfix;
}


/* 
 * Javascript
 */



/* 
 * Text
 */

.text-on-left {
  text-align: left !important; }

.text-on-right {
  text-align: right !important; }

.text-on-center {
  text-align: center !important; }

.text-justify {
  text-align: justify !important; }



/* 
 * Lists
 */

ul.no-bullets {
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
}


// make sure container is [position: relative]
// http://codepen.io/shshaw/full/gEiDt
%absolute-center {
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}



// Hide only visually, but have it available for screenreaders: h5bp.com/v
%visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// Hide visually and from screenreaders, but maintain layout

%invisible {
  visibility: hidden;
}

