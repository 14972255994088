/* 
 * Sprite
 * 
 * @package sasskit
 */


%sprite {
  background-image: url('../img/sprite.png');
  background-repeat: no-repeat;
  background-color: none;
}



