input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
	color: $black;
	border: 1px solid $black;
	border-radius: 3px;

	&:focus {

	}
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
	padding: 3px;
}

textarea {
  overflow: auto; /* Removes default vertical scrollbar in IE6/7/8/9 */
  padding-left: 3px;
  vertical-align: top; /* Improves readability and alignment in all browsers */
  width: 98%; }


input[type="checkbox"],
input[type="radio"] {
  padding: 0; /* Addresses excess padding in IE8/9 */ }


input[type="search"] {
  -webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
  -webkit-box-sizing: content-box; /* Addresses box sizing set to border-box in S5, Chrome (include -moz to future-proof) */
  -moz-box-sizing:    content-box;
  box-sizing:         content-box; }

input[type="search"]::-webkit-search-decoration { /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
  -webkit-appearance: none; }
