/* 
 * Medias
 * 
 * @package sasskit
 */

img {
  @include box-sizing;
  display: block;
  max-width: 100%;
  height: auto;
}

@import "lightbox";
@import "gallery";
@import "caption";

