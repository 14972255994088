

body,
button,
input,
select,
textarea {
  color: $color_text;
}


p {
  margin: 0 0 10px 0;
}

blockquote {
  margin: 0 $base_line_height;
  @include type-setting(1);
}

ul { 
}


b, strong {
  font-weight: bold;
}
dfn, cite, em, i {
  font-style: italic;
}
address {
  margin: 0 0 $base_line_height;
}
pre {
  background: #eee;
  font-family: $font_family_mono;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}
code, kbd, tt, var {
  font: 15px $font_family_mono;
}
abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}
mark, ins {
  background: #fff9c0;
  text-decoration: none;
}
sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  bottom: 1ex;
}
sub {
  top: .5ex;
}
small {
  font-size: 75%;
}
big {
  font-size: 125%;
}




