/* 
 * Set up specific templates : homes, single...
 * 
 * @package sasskit
 *
 */


  .hello {
    display: none;

    &:before {
      background-image: url('../images/TYPO_picto_@1');
      background-repeat: no-repeat;
      background-position: center center;
      display: block;
      width: 20px;
      height: 20px;
    }
  }
  



@import "homes";
@import "single";